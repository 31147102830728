import React from 'react'

import { CustomValue } from 'lij/Custom'

import Page from 'frame/Page'
import Menu from 'components/Menu'

const MenuPage = props => {
  return (
    <Page title="Candy Menu">
      <CustomValue
        item="customize.pages.Menu.items.Candy Menu"
        render={menu => <Menu menu={menu} />}
      />
    </Page>
  )
}

export default MenuPage