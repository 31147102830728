import styled, { css } from 'styled-components/macro'
import themeColor from 'lij/themeColor'
import themeProp from 'lij/themeProp'
import { Link as RRLink } from 'react-router-dom'
import get from 'lodash/get'
import { getBGTheme } from 'lij/Background'

function getThemeValue(defaults, name, fallback='inherit') {
  return props => {
    const value = props[name] || defaults[name] || fallback;
    return themeProp(value)(props) || value
  }
}

function getThemeFont(...args) {
  return props => {
    const value = getThemeValue(...args)(props)
    const faces = value.split(',').map(face => {
      face = face.trim()
      if (face.indexOf(' ') >= 0) {
        face = `"${face}"`
      }
      return face
    }).join(',')
    return faces
  }
}

const text = (defaults={}) => css`
  text-overflow: ellipsis;
  font-size: ${getThemeValue(defaults, 'size')};
  font-weight: ${getThemeValue(defaults, 'weight')};
  font-family: ${getThemeFont(defaults, 'family')};
  color: ${themeColor(defaults.color || 'inherit')};
  display: ${getThemeValue(defaults, 'display', 'block')};
  text-transform: ${getThemeValue(defaults, 'transform')};
  text-align: ${getThemeValue(defaults, 'align')};
  font-style: ${getThemeValue(defaults, 'fontStyle')};
  text-decoration: ${getThemeValue(defaults, 'decoration')};
  line-height: ${getThemeValue(defaults, 'lineHeight')};
  letter-spacing: ${getThemeValue(defaults, 'spacing')};
  margin: ${getThemeValue(defaults, 'margin', 'initial')};
  padding: ${getThemeValue(defaults, 'padding', 'initial')};
  transition: all .3s;
`

const getTextTheme = name => props => {
  return text(get(props, `theme.text.${name}`, {}))
}

const Text = styled.div`
  ${text()}
`

const Span = styled.span`
  ${getTextTheme('span')}
`

const H1 = styled.h1`
  ${getTextTheme('h1')}
`

const H2 = styled.h2`
  ${getTextTheme('h2')}
`

const P = styled.div`
  ${getTextTheme('p')}
`

const Para = styled.p`
  ${getTextTheme('p')}
`

const Label = styled.label`
  ${getTextTheme('label')}
`

const Link = styled(RRLink)`
  ${getTextTheme('a')}
`

const A = styled.a.attrs({ target: '_blank', rel: 'noopener noreferrer' })`
  ${getTextTheme('a')}
`

const buttonAttrs = props => ({
  background: 'text.button.background',
  border: 'text.button.border',
  color: 'text.button.border',
  padding: '1.4rem',
  size: '2rem',
  align: 'center',
  ...props,
})

const buttonCss = css`
  border-radius: 5px;
  width: 100%;
  border: 1px solid ${themeColor.use('border', 'clear')};
  ${getTextTheme('button')}
  ${getBGTheme('clear')}
`

const Button = styled.button.attrs(buttonAttrs)`
  ${buttonCss}
`

const ButtonLink = styled(RRLink).attrs(buttonAttrs)`
  ${buttonCss}
`

const ButtonA = styled.a.attrs(props => ({ ...buttonAttrs(props), target: '_blank', rel: 'noopener noreferrer' }))`
  ${buttonCss}
`

const ButtonLabel = styled.label.attrs(buttonAttrs)`
  ${buttonCss}
`

Button.Link = ButtonLink
Button.A = ButtonA
Button.Label = ButtonLabel

Text.Span = Span
Text.H1 = H1
Text.H2 = H2
Text.P = P
Text.Para = Para
Text.Label = Label
Text.Link = Link
Text.A = A
Text.Button = Button

export default Text