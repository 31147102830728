import React, { useState, useEffect } from 'react'
import styled, { useTheme } from 'styled-components'
import { useLocation } from 'react-router-dom'

import Text from 'lij/Text'
import BG from 'lij/Background'
import themeProp from 'lij/themeProp'

const Wrapper = styled(BG).attrs({ background: 'frame.menu.background' })`
  position: absolute;
  top: 0; left: 0; right: 0; bottom: 0;
  padding: ${themeProp('frame.page.padding')};
  z-index: 98;
  opacity: ${props => props.opacity};
  transition: background 2s, opacity .3s;
  border-radius: .5rem;
`

const MenuLink = props => {
  const theme = useTheme()
  const location = useLocation()
  const { to, children } = props

  const sameLocation = location.pathname === to

  const { menu } = theme.frame

  const button = menu.button[sameLocation ? 'disabled' : 'enabled']

  return (
    <Text.Button.Link to={to} {...button}>
      {children}
    </Text.Button.Link>
  )
}

const Contents = styled.div`
  position: sticky;
  top: ${themeProp('frame.menu.stickyTop')};
`

const MenuLinks = styled.div`
  display: grid;
  grid-gap: ${themeProp('frame.menu.gridGap')};
  margin-bottom: ${themeProp('frame.menu.gridGap')};
`

const EmptyComp = () => null
const emptyProps = {}


const Menu = props => {
  const { onMenu } = props

  const [opacity, setOpacity] = useState(0.3)

  useEffect(() => {
    setTimeout(() => setOpacity(1))
  }, [])

  return (
    <Wrapper opacity={opacity}>
      <Contents>
        <MenuLinks>
          {props.children}
        </MenuLinks>
        <Text.Button align="center" background="frame.menu.closeButton.background" color="frame.menu.closeButton.color" size="2rem" onClick={() => console.log('close click') || onMenu()}>Close</Text.Button>
      </Contents>
    </Wrapper>
  )
}

const Main = props => {
  const {
    pages,
    AboveLinksComp = EmptyComp,
    aboveLinksProps = emptyProps,
    ...otherProps
  } = props

  return (
    <Menu {...otherProps}>
      <AboveLinksComp {...aboveLinksProps} />
      {pages.map(page => (
        <MenuLink key={page.to} to={page.to}>{page.label}</MenuLink>
      ))}
    </Menu>
  )
}

Menu.Main = Main

export default Menu