import React from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Link } from 'react-router-dom'

import Text from 'lij/Text'
import BG from 'lij/Background'
import Icon from 'lij/Icon'
import themeColor from 'lij/themeColor'
import themeProp from 'lij/themeProp'

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: ${themeProp('frame.header.logo.size')} 1fr ${themeProp('frame.header.menu.size')};
  grid-gap: ${themeProp('frame.header.gridGap')};
  max-width: ${themeProp('frame.page.maxWidth')};
  padding: ${themeProp('frame.header.padding')};
  align-items: center;
  margin: auto;
`

const HeaderBG = styled(BG)`
  position: sticky;
  top: 0px;
  z-index: 99;
  transition: all .3s;

  ${props => props.sticking && css`
    box-shadow: 0 -2px 13px ${themeColor('frame.header.scrolled.shadow')};
  `}
`

const Header = props => {
  const { sticking, onMenu, title, logoIcon="LIJ" } = props
  const scrolled = sticking
  const theme = useTheme()
  const titleProps = theme.frame.header.title
  const titleColor = theme.frame.header[scrolled ? 'scrolled' : 'unscrolled'].title.color
  const menuColor = theme.frame.header[scrolled ? 'scrolled' : 'unscrolled'].menu.color
  const logoColor = theme.frame.header[scrolled ? 'scrolled' : 'unscrolled'].logo.color
  const bg = `frame.header.${scrolled ? '' : 'un'}scrolled.background`
  return (
    <HeaderBG background={bg} sticking={scrolled}>
      <HeaderWrapper>
        <Link to="/">
          <Icon size="frame.header.logo.size" color={logoColor} name={logoIcon} />
        </Link>
        <Text {...titleProps} color={titleColor}>{title}</Text>
        <Icon size="frame.header.menu.size" color={menuColor} onClick={onMenu} name="Menu" />
      </HeaderWrapper>
    </HeaderBG>
  )
}

export default Header