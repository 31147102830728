import sample from 'lodash/sample'

import 'frame/setupIcons'
import * as colors from 'frame/colors'
import pops from 'img/pops.webp'

const randomColor = () => sample("blue red green orange".split(' '))

function randomColorTheme() {
  const color = randomColor()
  const theme = {
    colors,
    text: {
      span: {
        display: "inline"
      },
      h1: {
        size: "3.4rem",
        weight: "600",
        margin: "2rem 0",
        family: "Codystar",
        color: "purple"
      },
      h2: {
        size: "2.8rem",
        weight: "600",
        margin: "2rem 0",
        family: "Codystar",
        color: "red"
      },
      p: {
        size: "1.8rem",
        lineHeight: "2.3rem",
        margin: "1rem 0"
      },
      label: {},
      a: {
        display: "inline",
        color: "blue"
      },
      button: {
        background: "blue",
        color: "white",
        transform: "capitalize"
      }
    },
    frame: {
      background: {
        color,
        image: [
          {
            url: pops,
            size: "100vw",
            repeat: "no-repeat",
            position: "right -8rem bottom -10rem"
          },
          {
            linearGradient: {
              options: "to bottom",
              stops: [
                {
                  color: "clear"
                },
                {
                  color: "purple"
                }
              ]
            }
          }
        ]
      },
      page: {
        maxWidth: "84rem",
        background: "white",
        shadow: "black@.5",
        borderRadius: "0.5rem",
        margin: "0 auto 2rem",
        padding: "2rem",
        wrapperPadding: "0 2rem",
        title: {
          shadow: "clear",
          margin: "0"
        }
      },
      header: {
        gridGap: "1rem",
        padding: "1rem 2rem 1rem",
        scrolled: {
          background: "white",
          shadow: "black",
          title: {
            color: color,
          },
          logo: {
            color: color
          },
          menu: {
            color: `${color}@.75`
          }
        },
        unscrolled: {
          background: "clear",
          title: {
            color: "white"
          },
          logo: {
            color: "white"
          },
          menu: {
            color: "white"
          }
        },
        title: {
          spacing: ".5rem",
          size: "1.4rem",
          transform: "uppercase",
          align: "left"
        },
        menu: {
          size: "3rem"
        },
        logo: {
          size: "3rem"
        }
      },
      menu: {
        gridGap: "2rem",
        stickyTop: "8rem",
        background: `${color}@.85`,
        button: {
          enabled: {
            background: "white@.8",
            shadow: "black",
            size: "2rem",
            color: "black"
          },
          disabled: {
            background: color,
            shadow: "black",
            size: "2rem",
            color: "black@.5"
          }
        },
        closeButton: {
          background: "clear",
          color: "white"
        }
      },
      builtBy: {
        logo: "white",
        overtext: "white",
        company: "white",
        undertext: "clear"
      }
    },
    components: {
      loader: {
        color: "purple"
      }
    }
  }

  return theme
}

export default randomColorTheme