import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'

import Theme from 'lij/theme'
import GlobalStyle from 'lij/GlobalStyle'
import BG from 'lij/Background'
import { CustomProvider } from 'lij/Custom'

import Home from './pages/Home'
import Menu from './pages/Menu'
import randomColorTheme from 'frame/randomColorTheme'

const Wrapper = styled(BG).attrs({
  background: 'frame.background'
})`
  overflow: scroll;
  height: 100%;
  min-height: var(--app-height);
`

if (window.location.hostname !== 'localhost') {
  if (!window.parent || window.parent === window) {
    window.location.href = 'https://www.ellijaywebdesign.com'
  }
}

function App() {
  const [tick, setTick] = useState(1)

  useEffect(() => {
    const x = (x => x)
    x(tick)
    setTimeout(() => {
      setTick(t => t+1)
    }, 2000)
  }, [tick])

  return (
    <HelmetProvider>
      <Theme theme={randomColorTheme()}>
        <GlobalStyle />
        <Router>
          <CustomProvider>
            <Wrapper id="main-wrapper">
            <Switch>
              <Route path="/menu">
                <Menu />
              </Route>
              <Route path="/">
                <Home />
              </Route>
              <Redirect to="/" />
            </Switch>
            </Wrapper>
          </CustomProvider>
        </Router>
      </Theme>
    </HelmetProvider>
  )
}

export default App
