import React from 'react'
import styled from 'styled-components'

import Page from 'frame/Page'
import ImageSlider from 'components/ImageSlider'

import thumbnail from 'lij/thumbnail'
import BG from 'lij/Background'
import themeColor from 'lij/themeColor'
import { CustomValue } from 'lij/Custom'
import Text from 'lij/Text'
import Spacer from 'lij/Spacer'

const Banner = styled(BG).attrs({ background: 'blue@.5' })`
margin: 2rem -2rem;
border-top: 5px dashed ${themeColor.locked('white@.5')};
border-bottom: 5px dashed ${themeColor.locked('white@.5')};
padding: 0 2rem 2rem;
`

const Pre = styled(Text.P)`
white-space: pre-wrap;
`

const Home = props => {
  return (
    <Page title="Candy Store">
      <Banner>
        <Text.H1 family="inherit" color="black">Playground</Text.H1>
        
        <CustomValue
          item="customize.pages.Home.items.Welcome Description"
          render={value => (
            <Pre>{value}</Pre>
          )}
        />
      </Banner>

      <Text.H2>Candy Store</Text.H2>

      <CustomValue
        item="customize.pages.Home.items.Gallery"
        render={images => images.length ? (
          <ImageSlider key={String(images.length)} images={images.map(i => thumbnail('540x360',i.url))} />
        ) : null}
      />

      <Spacer height="1rem" />

      <Text.Button.Link background="purple" to="/menu">Check Out Our Candies!</Text.Button.Link>

      <Spacer height="3rem" />
      
    </Page>
  )
}

export default Home