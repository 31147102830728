import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import Background from 'lij/Background'

const Container = styled.div`
  width: 100%;
  overflow-x: hidden;
`

const Slider = props => {
  const { images } = props

  const [current, setCurrent] = useState(0)

  const next = useCallback(c => {
    setCurrent((c + 1) % images.length)
  }, [images.length])

  useEffect(() => {
    const int = setTimeout(() => next(current), 5000)
    return () => clearTimeout(int)
  }, [current, next])

  return (
    <Container>
      {images.map(i => <img key={i} src={i} alt="" style={{display: 'none'}} />)}
      <Background.Image aspect={180/270} background={{image: images[current]}} />
    </Container>
  )
}

export default Slider