import React, { useState, useCallback, useEffect } from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet-async'
import { useInView } from 'react-intersection-observer'
import { useHistory } from 'react-router-dom'

import Menu from 'lij/Menu'
import Spacer from 'lij/Spacer'
import Header from 'lij/Header'
import themeProp from 'lij/themeProp'
import themeColor from 'lij/themeColor'
import BG from 'lij/Background'
import BuiltBy from 'lij/BuiltBy'


const PageWrapper = styled.div`
  min-height: var(--app-height);
`

const MenuContainer = styled.div`
  position: relative;
`

const PageContentWrapper = styled(BG).attrs({ background: 'frame.page.background' })`
  margin: ${themeProp('frame.page.margin')};
  border-radius: ${themeProp('frame.page.borderRadius')};
  box-shadow: 0 5px 5px ${themeColor('frame.page.shadow')};
  padding: ${themeProp('frame.page.padding')};
  min-height: calc(var(--app-height) - 30rem);
  display: grid;
  grid-template-rows: min-content 1fr min-content;
`

const Pad = styled.div`
  padding: ${themeProp('frame.page.wrapperPadding')};
  max-width: ${themeProp('frame.page.maxWidth')};
  margin: auto;
`

const Footer = props => {
  return <BuiltBy />
}

const EmptyComp = () => null
const emptyProps = {}

const Clear = styled.div`
  clear: both;
`

const Page = props => {
  const {
    title,
    TitleComp=EmptyComp,
    titleProps=emptyProps,
    children, 

    HeaderComp = Header,
    MenuComp = Menu.Main,
    FooterComp = Footer,
    
    headerProps=emptyProps,
    menuProps=emptyProps,
    footerProps=emptyProps,

    AboveHeaderComp = EmptyComp,
    aboveHeaderProps = emptyProps,

    AbovePageContentComp = EmptyComp,
    abovePageContentProps = emptyProps,

    PageContentBottomComp = EmptyComp,
    belowPageContentBottomProps = emptyProps,

    BelowPageContentComp = EmptyComp,
    belowPageContentProps = emptyProps,

    AboveFooterComp = EmptyComp,
    aboveFooterProps = emptyProps,
  
  } = props

  const [showMenu, setShowMenu] = useState(false)

  const { action } = useHistory()

  const handleMenu = useCallback(() => {
    setShowMenu(was => !was)
  }, [])

  const [ref, inView] = useInView({
    initialInView: true
  })

  useEffect(() => {
    if (action === 'PUSH') {
      // Going to a new page, scroll to top
      document.getElementById("main-wrapper").scrollTo(0,0)
    }
  }, [action])

  return (
    <PageWrapper id="page">
      <Helmet>
        <title>{title || ''}</title>
      </Helmet>
      <div ref={ref} />
      <AboveHeaderComp {...aboveHeaderProps} />
      <HeaderComp title={title} sticking={!inView} onMenu={handleMenu} {...headerProps} />
      <Pad>
        <TitleComp {...titleProps}>{title}</TitleComp>
        <MenuContainer>
          <AbovePageContentComp {...abovePageContentProps} />
          <PageContentWrapper>
            <div>{children}</div>
            <Clear />
            <PageContentBottomComp {...belowPageContentBottomProps} />
          </PageContentWrapper>
          <BelowPageContentComp {...belowPageContentProps} />
          {showMenu && <MenuComp onMenu={handleMenu} {...menuProps} />}
        </MenuContainer>
        <AboveFooterComp {...aboveFooterProps} />
        <FooterComp {...footerProps} />
      </Pad>
      <Spacer height="3rem" />
    </PageWrapper>
  )
}

export default Page