import React from 'react'
import Page from 'lij/Page'

const FramePage = props => {
  return (
    <Page
      {...props}
      menuProps={{
        pages:[
          { to: "/", label: 'Home' },
          { to: "/menu", label: 'Candy Menu' },
        ]
      }}
      headerProps={{logoIcon: 'Candy'}}
    />
  )
}

export default FramePage