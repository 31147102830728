import styled, { css } from 'styled-components'
import get from 'lodash/get'

import themeColor from 'lij/themeColor'
import media from 'lij/media'

// A = "red"
// B = {
//   color: 'red',
//   image: 'url'
// }
// C = {
//   color: 'red',
//   image: ['url', 'url']
// }
// D = {
//   color: 'red',
//   image: {}
// }
// E = {
//   color: 'red',
//   image: [{}, {}]
// }
// F = [{}, {}]

function handleImageSet(items) {
  return props => {
    const parts = []
    items.forEach(item => {
      const { url, res } = item
      parts.push(`url(${url}) ${res}`)
    })
    return `${parts.join(', ')}`
  }
}

function handleLinearGradient(obj) {
  return props => {
    const { options, stops } = obj
    const parts = []
    if (options) {
      parts.push(options)
    }
    stops.forEach(stop => {
      const { color, position } = stop
      if (position) {
        parts.push(`${themeColor.locked(color)(props)} ${position}`)
      } else {
        parts.push(`${themeColor.locked(color)(props)}`)
      }
    })
    return `${parts.join(', ')}`
  }
}


function handleColor(value) {
  if (typeof value === 'string') {
    return themeColor.locked(value)
  }
  if (typeof value === 'object' && !Array.isArray(value) && value.color) {
    return themeColor.locked(value.color)
  }
  return 'initial'
}

function handleImage(value, prop) {
  return props => {
    const defaultImage = 'none'
    const defaultSize = 'cover'
    const defaultAttachment = 'scroll'
    const defaultPosition = 'top'
    const defaultRepeat = 'repeat'

    if (typeof value === 'string') {
      return [{
        url: defaultImage,
        size: defaultSize,
        attachment: defaultAttachment,
        position: defaultPosition,
        repeat: defaultRepeat,
      }]
    }
    if (typeof value === 'object' && !Array.isArray(value)) {
      const inner = value.image || value
      if (Array.isArray(inner)) {
        return handleImage(inner, prop)
      } else {
        return handleImage([inner], prop)
      }
    }

    let images = [], sizes = [], attachments = [], positions = [], repeats = []
    
    value.forEach(val => {
      if (typeof val === 'string') {
        images.push(`url(${val})`)
        sizes.push(defaultSize)
        attachments.push(defaultAttachment)
        positions.push(defaultPosition)
        repeats.push(defaultRepeat)
      } else {
        const { url, linearGradient, imageSet, size, attachment, position, repeat } = val
        if (imageSet) {
          images.push(`image-set(${handleImageSet(imageSet)(props)})`)
        } else if (linearGradient) {
          images.push(`linear-gradient(${handleLinearGradient(linearGradient)(props)})`)
        } else {
          images.push(url ? `url(${url})` : defaultImage)
        }
        sizes.push(size || defaultSize)
        attachments.push(attachment || defaultAttachment)
        positions.push(position || defaultPosition)
        repeats.push(repeat || defaultRepeat)
      }
    })

    const all = {
      image: images.join(', '),
      size: sizes.join(', '),
      attachment: attachments.join(', '),
      position: positions.join(', '),
      repeat: repeats.join(', '),
    }

    return all[prop]
  }
}

const background = bg => css`
  background-color: ${handleColor(bg)};
  background-image: ${handleImage(bg, 'image')};
  background-size: ${handleImage(bg, 'size')};
  background-attachment: ${handleImage(bg, 'attachment')};
  background-position: ${handleImage(bg, 'position')};
  background-repeat: ${handleImage(bg, 'repeat')};
  transition: all 2s;

  ${handleMedia(bg)}
`

function handleMedia(value) {
  if (typeof value === 'object' && typeof value.media === 'object') {
    return Object.keys(value.media).reduce((str, key) => {
      const bg = value.media[key]
      return css`
        ${str}
        ${media(key)`
          ${background(bg)}
        `}
      `
    }, '')
  }
}

export const getBGTheme = (defaults = "clear") => props => {
  return background(get(props.theme, props.background, (props.background || defaults)))
}

const BG = styled.div`
  ${getBGTheme()}
`

const Image = styled(BG)`
  padding-top: ${props => props.aspect * 100}%;
`

BG.Image = Image

export default BG